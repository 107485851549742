import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import { kebabCase } from 'lodash'
import { IconContext } from 'react-icons'
import { FiTag } from 'react-icons/fi'

// type
type tagDataType = {
  tag: string
  totalCount: number
}

const Tags: React.FC = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          tags: allMarkdownRemark(limit: 1000) {
            group(field: frontmatter___tags) {
              tag: fieldValue
              totalCount
            }
          }
        }
      `}
      render={(data) => (
        <ul className="flex flex-wrap mt-2">
          {data.tags.group.map((tagData: tagDataType) => (
            <li
              key={`${tagData.tag}tag`}
              className="flex m-2 bg-backgroundColor1 rounded-lg ring ring-backgroundColor2 px-2 py-1"
            >
              <Link to={`/tags/${kebabCase(tagData.tag)}/`}>
                <span className="flex flex-row text-sm sm:text-lg">
                  <IconContext.Provider
                    value={{ className: 'text-md sm:text-xl mt-1' }}
                  >
                    <FiTag />
                  </IconContext.Provider>
                  &nbsp;{tagData.tag} ({tagData.totalCount})
                </span>
              </Link>
            </li>
          ))}
        </ul>
      )}
    />
  )
}

export default Tags
