import React from 'react'
import { Link, graphql } from 'gatsby'

import DefaultLayout from '../components/Layout/default'
import SEO from '../components/SEO'
import CardGrid from '../components/Article/CardGrid'
import Tags from '../components/Tags'

// type
import {
  SitePageContext,
  BlogPagingTemplateQuery,
} from '../../types/graphql-types'

// interface
interface BlogPagingTemplateProps {
  data: BlogPagingTemplateQuery
  pageContext: SitePageContext
}

const BlogPagingTemplate: React.FC<BlogPagingTemplateProps> = ({
  data,
  pageContext,
}) => {
  return (
    <DefaultLayout title={`ブログ:${pageContext.humanPageNumber}`}>
      <SEO
        title={`ブログ一覧:${pageContext.humanPageNumber}ページ目`}
        keywords={[
          '経済',
          'ブログ',
          'Notion',
          '投資',
          'プログラミング',
          'デザイン',
        ]}
      />

      <div className="container mx-auto">
        <div className="pt-10 sm:py-10 flex flex-col sm:flex-row mx-5">
          <div className="flex flex-col w-full sm:w-2/3 sm:ml-5">
            <CardGrid articleEdges={data.allMarkdownRemark.edges}>
              <div className="flex flex-row justify-center">
                <div className="block m-3 md:inline-block md:mt-0 text-gray-800 hover:text-linkColor hover:underline mr-4">
                  {pageContext.previousPagePath ? (
                    <Link to={pageContext.previousPagePath}>←前のページ</Link>
                  ) : null}
                </div>

                <div className="block m-3 md:inline-block md:mt-0 text-gray-800 hover:text-linkColor hover:underline mr-4">
                  {pageContext.nextPagePath ? (
                    <Link to={pageContext.nextPagePath}>次のページ→</Link>
                  ) : null}
                </div>
              </div>
            </CardGrid>
          </div>

          <div className="flex flex-col w-full sm:w-1/3 mt-10 sm:pl-10">
            <h2 className="text-2xl">すべてのタグ</h2>

            <Tags />
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}
export default BlogPagingTemplate

export const pageQuery = graphql`
  query BlogPagingTemplate($skip: Int!, $limit: Int!) {
    tags: allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        tag: fieldValue
        totalCount
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt(pruneLength: 100)
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
            tags
            templateKey
            date(formatString: "MMMM DD, YYYY")
            featuredpost
            featuredimage {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`
